import React from "react";

import { BaseComponent } from "../../utils/BaseComponent";
import { Overlay, OverlayProps } from "../Overlay";
import { ViewerPlugin } from "./ViewerPlugin";

import ViewerSwitch from "../switch/Switch";

import "./Viewer.scss";
import { AngleFromXY, AngleDelta } from "../../utils/BasicFunctions";

import logo from "../../assets/logo.png";

interface RotateData {
    active: boolean;
    startAngle: number | undefined;
    centerX: number | undefined;
    centerY: number | undefined;
    startFrame: number | undefined;
}

export interface ViewerProps {
    viewerPlugin: ViewerPlugin;
    apartments: { [key: string]: boolean };
    apartmentsAvailability: { [key: string]: 1 | 2 | 3 };
    className?: string;
    hideSwitchElementsVisibility?: boolean;
    hideViewButtons?: boolean;
    onElementClick?: (id: string) => void;
    selectedApartmentId?: string;
}

export default class Viewer extends BaseComponent<ViewerProps> {
    private viewerRef = React.createRef<HTMLDivElement>();

    private rotateData: RotateData = {
        active: false,
        startAngle: undefined,
        centerX: undefined,
        centerY: undefined,
        startFrame: undefined,
    };

    render() {
        return (
            <div className={`viewer ${this.props.className || ""}`} ref={this.viewerRef}>
                {/* <img src={logo} className="viewer__logo" /> */}

                {!this.props.hideViewButtons ? (
                    <div className="viewer__views-buttons">
                        <div className="viewer__views-buttons-wrapper">
                            {APP_CONFIG.viewerConfig.apartmentsButtons.map((ab, index) => (
                                <button
                                    className={`button white-green ${
                                        ab.viewId === this.plugin.storage.viewId ? "is-on" : ""
                                    }`}
                                    key={index}
                                    onClick={() => this.plugin.openView(ab.viewId)}>
                                    {ab.label}
                                </button>
                            ))}
                        </div>
                    </div>
                ) : null}

                <div className="viewer__right-buttons">
                    <ViewerSwitch
                        iconOn="wb_sunny"
                        iconOff="brightness_3"
                        state={this.plugin.storage.viewScene === "Day"}
                        changeState={() => this.plugin.changeScene()}
                    />
                    {!this.props.hideSwitchElementsVisibility ? (
                        <ViewerSwitch
                            iconOn="visibility"
                            iconOff="visibility_off"
                            state={this.plugin.storage.visibleElements}
                            changeState={() => this.plugin.changeElementVisibility()}
                        />
                    ) : null}
                </div>
                <div className="viewer__buttons-bottom">
                    <button className="button icon" onClick={() => this.plugin.scale(1)}>
                        add
                    </button>
                    <button className="button icon" onClick={() => this.plugin.scale(-1)}>
                        remove
                    </button>
                    <button className="button icon" onClick={() => this.plugin.move(-1)}>
                        keyboard_arrow_left
                    </button>
                    <button className="button icon" onClick={() => this.plugin.move(1)}>
                        keyboard_arrow_right
                    </button>
                </div>
                <div className="viewer__support button">?</div>
                <div className="viewer__images-wrapper">
                    <div className="viewer__orientation">
                        <div
                            className="viewer__orientation-circle"
                            style={{ transform: `rotate(${(this.plugin.direction * 360) / 1000}deg)` }}>
                            <div className="viewer__orientation-circle-label">N</div>
                        </div>
                    </div>
                    {this.overlayProps.show ? (
                        <div className="viewer__loader">
                            <Overlay {...this.overlayProps} />
                        </div>
                    ) : null}
                    <div
                        className={`viewer__images-container
                    ${this.plugin.storage.hide ? "hide" : ""} 
                    ${this.plugin.storage.viewScene}
                    index-${this.plugin.storage.frameIndex}`}
                        style={{ transform: `scale(${this.plugin.storage.scale})` }}
                        onMouseDown={e => this.rotateStart(e.clientX, e.clientY)}
                        onMouseMove={e => this.rotateMove(e.clientX, e.clientY)}
                        onMouseLeave={() => this.rotateStop()}
                        onMouseUp={() => this.rotateStop()}
                        onTouchStartCapture={event => this.onTouchStart(event)}
                        onTouchMove={event => this.onTouchMove(event)}
                        onTouchEnd={() => this.rotateStop()}
                        onContextMenu={e => e.preventDefault()}>
                        <div className="viewer__images day" ref={this.plugin.imagesDayRef}></div>
                        <div className="viewer__images night" ref={this.plugin.imagesNightRef}></div>
                        <div className="viewer__elements">
                            {this.plugin.storage.visibleElements ? (
                                <svg className="viewer__elements-svg" viewBox="0 0 1600 1200">
                                    {this.plugin.frameElements.map((element, index) => {
                                        if (element.A.T === "A" && !this.props.apartments[element.A.I]) return null;
                                        const avaiability = this.props.apartmentsAvailability[element.A.I];

                                        return (
                                            <polygon
                                                className={`viewer__element ${
                                                    element.A.T === "A"
                                                        ? avaiability === 2
                                                            ? "is-reserved"
                                                            : avaiability === 3
                                                            ? "is-sold"
                                                            : ""
                                                        : ""
                                                } ${
                                                    element.A.T === "A" &&
                                                    element.A.I === this.plugin.storage.hoverElementIndex
                                                        ? "is-hover"
                                                        : ""
                                                } ${
                                                    element.A.T === "A" &&
                                                    element.A.I === this.props.selectedApartmentId
                                                        ? "is-highlight"
                                                        : ""
                                                }`}
                                                key={`${element.A.I}-${element.A.T}-${index}`}
                                                points={element.P.map(
                                                    p => `${(p[0] * 1600) / 100000} ${(p[1] * 1200) / 100000}`
                                                ).join(", ")}
                                                onMouseOver={() => {
                                                    if (element.A.T === "A") {
                                                        this.plugin.hoverElement(element.A.I);
                                                    }
                                                }}
                                                onMouseOut={() => {
                                                    if (element.A.T === "A") {
                                                        this.plugin.hoverElementOut(element.A.I);
                                                    }
                                                }}
                                                onMouseDown={e => e.stopPropagation()}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    if (this.props.onElementClick) {
                                                        this.props.onElementClick(element.A.I);
                                                    }
                                                }}
                                            />
                                        );
                                    })}
                                </svg>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    get plugin() {
        return this.props.viewerPlugin;
    }

    get overlayProps(): OverlayProps {
        const viewLoaderState = this.context.ViewLoader.storage.Views[this.plugin.storage.viewId];
        if (!viewLoaderState) {
            return {
                show: true,
                title: "Widok nie istnieje",
            };
        }
        const viewLoaderSceneState = viewLoaderState[this.plugin.storage.viewScene];
        if (viewLoaderSceneState.LoadState.state === "idle") {
            return {
                show: true,
                title: "Oczekuje na pobieranie widoku...",
            };
        }
        if (viewLoaderSceneState.LoadState.state === "pending") {
            return {
                show: true,
                title: "Pobieranie widoku...",
                children: (
                    <div className="viewer__overlay-progress">
                        {Math.round((viewLoaderSceneState.LoadState.percent * 8) / 10)}%
                    </div>
                ),
            };
        }
        if (viewLoaderSceneState.LoadState.state === "error") {
            return {
                show: true,
                title: "Błąd podczas pobierania widoku",
                children: (
                    <div>
                        <button
                            onClick={() =>
                                this.context.ViewLoader.unzipQueueAddForce(
                                    this.plugin.storage.viewId,
                                    this.plugin.storage.viewScene
                                )
                            }>
                            Pobierz ponownie
                        </button>
                    </div>
                ),
            };
        }
        if (viewLoaderSceneState.UnzipState.state === "idle") {
            return {
                show: true,
                title: "Rozpakowywanie widoku",
                children: <div className="viewer__overlay-progress">80%</div>,
            };
        }
        if (viewLoaderSceneState.UnzipState.state === "pending") {
            return {
                show: true,
                title: "Rozpakowywanie widoku",
                children: (
                    <div className="viewer__overlay-progress">
                        {80 + Math.round((viewLoaderSceneState.UnzipState.percent * 2) / 10)}%
                    </div>
                ),
            };
        }
        if (viewLoaderSceneState.UnzipState.state === "error") {
            return {
                show: true,
                title: "Błąd podczas rozpakowywania widoku",
                children: (
                    <div>
                        <button
                            onClick={() =>
                                this.context.ViewLoader.unzipQueueAddForce(
                                    this.plugin.storage.viewId,
                                    this.plugin.storage.viewScene
                                )
                            }>
                            Pobierz ponownie
                        </button>
                    </div>
                ),
            };
        }
        return {
            show: false,
        };
    }

    private rotateStart(x: number, y: number) {
        this.plugin.animateAbort();
        const container = this.viewerRef.current;
        if (!!container) {
            this.rotateData.centerX = container.offsetWidth / 2;
            this.rotateData.centerY = -200;
            // this.rotateData.centerY = container.offsetHeight / 2;

            this.rotateData.startAngle = AngleFromXY(this.rotateData.centerX, this.rotateData.centerY, x, y);
        } else {
            this.rotateData.startAngle = x;
        }

        this.rotateData.active = true;
        this.rotateData.startFrame = this.plugin.storage.frameIndex;
    }

    private rotateMove(x: number, y: number) {
        if (!this.rotateData.active) return;
        const max = this.plugin.currentViewConfig?.framesCount || 0;

        if (this.rotateData.centerX !== undefined && this.rotateData.centerY !== undefined) {
            const currentAngle = AngleFromXY(this.rotateData.centerX, this.rotateData.centerY, x, y);
            const deltaAngle = AngleDelta(this.rotateData.startAngle || 0, currentAngle) * 2;

            const deltaIndex = Math.round((deltaAngle * max) / 360);

            const newIndex = ((this.rotateData.startFrame || 0) + deltaIndex) % max;

            this.plugin.setIndex(newIndex);
        } else {
        }
    }

    private rotateStop() {
        this.rotateData.active = false;
        this.rotateData.startAngle = undefined;
        this.rotateData.startFrame = undefined;
        this.rotateData.centerX = undefined;
        this.rotateData.centerY = undefined;
        this.plugin.moveEnded();
    }

    private onTouchStart(event: React.TouchEvent<HTMLDivElement>) {
        this.rotateStart(event.touches[0].clientX, event.touches[0].clientY);
    }

    private onTouchMove(event: React.TouchEvent<HTMLDivElement>) {
        this.rotateMove(event.touches[0].clientX, event.touches[0].clientY);
    }
}
