import { BaseContext, ContextStorage } from "./Base";
import axios, { AxiosResponse } from "axios";
import {
    Apartment,
    ApartmentsConfig,
    ApartmentPropDefinition,
} from "../types/Config.types";

export interface ApartmentConfigLoaderStorage {
    State: "idle" | "pending" | "completed" | "error";
    Apartments: Apartment[];
    PropDefinitions: ApartmentPropDefinition[];
}

type ListenerFunction = () => void;

export type ApartmentConfigLoaderStorageKeyType = "ApartmentConfigLoader";

export const ApartmentConfigLoaderStorageKey: ApartmentConfigLoaderStorageKeyType =
    "ApartmentConfigLoader";

export const ApartmentConfigLoaderStorageDefault: ContextStorage<
    ApartmentConfigLoaderStorageKeyType,
    ApartmentConfigLoaderStorage
> = {
    ApartmentConfigLoader: {
        State: "idle",
        Apartments: [],
        PropDefinitions: [],
    },
};

export class ApartmentConfigLoader extends BaseContext<
    ApartmentConfigLoaderStorage,
    ApartmentConfigLoaderStorageKeyType
> {
    private listeners: ListenerFunction[] = [];

    public async init() {
        return;
        this.parentSetState(p => ({ State: "pending" }));
        try {
            const response: AxiosResponse<ApartmentsConfig> = await axios({
                url: APP_CONFIG.apartmentsConfigUrl,
            });
            this.parentSetState(
                p => ({
                    State: "completed",
                    Apartments: response.data.apartments,
                    PropDefinitions: response.data.propDefinitions,
                }),
                () => {
                    this.listeners.forEach(l => l());
                    this.listeners.length = 0;
                }
            );
        } catch (e) {
            console.log(e);
            this.parentSetState(p => ({ State: "error" }));
        }
    }

    public listen(f: ListenerFunction) {
        f();
        return;
        if (this.storage.State === "completed") {
            f();
        }
        this.listeners.push(f);
    }

    get apartments() {
        return APARTMENT_CONFIG.apartments;
        return this.storage.Apartments;
    }

    get propDefinitions() {
        return APARTMENT_CONFIG.propDefinitions;
        return this.storage.PropDefinitions;
    }
}
